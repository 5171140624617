<template>
  <div id="reportTable" class="table-responsive" :class="{'adt' : develop == 'adt'}">
    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>
    <b-row v-if="this.$route.name == 'tournament'">
        <span class="reportsPad">
            <b-col  class="dropSel">
                <b-row class="reportDropdown">
                    <b-col>
                    <div :class="drop">
                        <b-nav card-header pills>
                            <b-dropdown
                                v-if="Array.isArray(tmparams.reports.reports_entry)"
                                :text="title"
                                class="dropdownReport"
                            >
                                <b-dropdown-item
                                v-on:click="changeReport"
                                v-for="(reports, index) in filterExemptions"
                                :key="index"
                                v-show="
                                    reports.report_title !== 'Live Scoring Monitor'
                                "
                                :title="reports.report_url"
                                :value="reports.report_title"
                                >{{ reports.report_title }}</b-dropdown-item
                                >
                            </b-dropdown>
                            <!-- <b-dropdown
                                v-else
                                :text="title"
                                class="dropdownReport"
                            >
                                <b-dropdown-item
                                v-on:click="changeReport"
                                v-for="(reports, index) in tmparams.reports.reports_entry"
                                :key="index"
                                v-show="
                                    reports.report_title !== 'Live Scoring Monitor'
                                "
                                :title="reports.report_url"
                                :value="reports.report_title"
                                >{{ reports.report_title }}</b-dropdown-item
                                >
                            </b-dropdown> -->
                        </b-nav>
                    </div>
                    </b-col>
                </b-row>
            </b-col>
        </span>
    </b-row>
    <table>
      <thead ></thead>
      <tbody :class="{'adtBK' : develop =='adt'}"> 
        <template v-if="tablePick === 'Entries'">
          <Entries
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :develop="develop"
            :config="config"
            :match="match"
            :team="team"
            :code="code"
            :season="season"
            :showGender="showGender"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :showmore="showmore"
          />
        </template>
        <template v-if="tablePick === 'Draw'">
          <Draw
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :develop="develop"
            :config="config"
            :match="match"
            :team="team"
            :code="code"
            :season="season"
            :showGender="showGender"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :showmore="showmore"
          />
        </template>
        <template v-if="tablePick === 'Score' || tablePick === 'Live'">
          <Score
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :develop="develop"
            :config="config"
            :match="match"
            :team="team"
            :code="code"
            :season="season"
            :showGender="showGender"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :Stableford="Stableford"
            :showFav="showFav"
            :is_qualifier="is_qualifier"
            :showmore="showmore"
          />
        </template>
        <template v-if="tablePick === 'Final'">
          <Results
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :develop="develop"
            :config="config"
            :match="match"
            :team="team"
            :code="code"
            :season="season"
            :showGender="showGender"
            :Stableford="Stableford"
            :dataShowOOM="data.show_winnings"
            :dataHavePoints="data.have_points"
            :dataShowPointsName="data.show_points_name"
            :dataHaveOom="data.have_oom"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :roundsplayed="data.rounds_played"
            :is_qualifier="is_qualifier"
            :showmore="showmore"
            :Year="Year"
            :course_code="course_code"
          />
        </template>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <template v-if="tablePick === 'Final'">
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.pos }}
              </td>
              <td
                class="posnum"
                v-if="
                  index !== 0 &&
                    (tabledata.tied !== '=' ||
                      Eds[index - 1].pos !== tabledata.pos) &&
                    tabledata.pos !== 'CUT'
                "
              >
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td
                class="posnum"
                v-else-if="index === 0 && tabledata.pos !== 'CUT'"
              >
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td v-else-if="tabledata.pos !== 'CUT'" class="noPos">
                <span>.</span>
              </td>

              <td
                v-if="tabledata.playerno !== 'Cat' && tabledata.pos !== 'CUT'"
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down'
                }"
              >
                <a
                  :href="'/playerprofile/' + tabledata.member_no + '/' + season"
                >
                  {{ tabledata.playing_name }}
                  <span v-if="tabledata.sponsored === 'Y'"
                    ><b-img :src="config.VUE_APP_TITLEIST"></b-img
                  ></span>
                </a>
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT'">
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td v-if="tabledata.pos !== 'CUT'">
                {{ tabledata.winnings }}
              </td>
              <template v-if="match === 'N' && tabledata.pos !== 'CUT'">
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.vspar }}
                </td>
                <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                  {{ tabledata.score }}
                </td>
                <td
                  v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                  class="down"
                >
                  {{ tabledata.score }}
                </td>
                <td v-else-if="roundsPlayed > 1">
                  {{ tabledata.score }}
                </td>
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span
                    v-else-if="
                      tabledata['score_R' + i] < data.course_total_par.slice(1)
                    "
                    :class="up"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span
                    v-else-if="
                      tabledata['score_R' + i] > data.course_total_par.slice(1)
                    "
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else>{{ tabledata["score_R" + i] }}</span>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a
                  :href="'/playerprofile/' + tabledata.playerno + '/' + season"
                >
                  <font-awesome-icon
                    style="color:#337ab7!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
            </template>
          </tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
              />
            </td>
          </tr>
        </template>
        <template>
          <div
            class="LiveScoreMess"
            v-if="data.live_scoring_mess"
            :class="{ colorMess: data.live_scoring_mess.includes('suspended') }"
          >
            <p class="LiveScoreMessPara">{{ data.live_scoring_mess }}</p>
          </div>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import {
  Entries,
  Draw,
  Score,
  PlayerReportCard,
  Results
} from "@ocs-software/vue-ocs";
export default {
  name: "reportTable",
  props: [
    "id",
    "data",
    "previousData",
    "home",
    "title",
    "develop",
    "season",
    "code",
    "config",
    "team",
    "match",
    "showGender",
    "Stableford",
    "represents_ind",
    "represents_heading",
    "represents_flag_type",
    "showFav",
    "is_qualifier",
    "tmparams",
    "showmore",
    "Year",
    "course_code"
  ],
  components: { 
    PlayerReportCard, 
    Entries, 
    Draw, 
    Score, 
    Results 
  },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win"
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      console.log("report")
      console.log(report)
      var title = event.target.getAttribute("value");
      console.log("title")
      console.log(title)
      this.title = title;
      return (
        (this.currentReport = report),
        (console.log("this.currentReport")),
        (console.log(this.currentReport)),
        axios
          .get(this.reportTitle)
          .then(response => 
            (this.data = response.data),
            (console.log("this.data")),
            (console.log(this.data)),
            console.log("this.reportTitle"),
            console.log(this.reportTitle),
          )
      );
    },
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          if (this.currentReport.includes("qs.ocs-asia.com")) {
            return axios.get(
              this.config.VUE_APP_TIC_BASE +
                "qsc" +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-tmticx.json?randomadd=" +
                new Date().getTime()
            );
          } else {
            return axios.get(
              this.config.VUE_APP_TIC_BASE +
                this.develop +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-tmticx.json?randomadd=" +
                new Date().getTime()
            );
          }
        })
        .then(response => {
          this.tmparams = response.data;
        });
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            "https://api.asia.ocs-software.com/apga/cache/" +
              this.develop +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => (this.playerCardData = response.data))
      );
    }
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result") 
          // !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    reportTitle: function(url) {
      if (this.currentReport.includes("qs.ocs-asia.com")) {
        if  (this.tablePick === "Entries") {
          url =
            process.env.VUE_APP_TIC_BASE +
            "qsc" +
            "/" +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-entry-entries.json";
        } else {
          if (this.currentReport.indexOf("tmscore") !== -1) {
            var subRep3 = this.currentReport.lastIndexOf("params=") + 7;
            var indNext3 = this.currentReport.indexOf("~", subRep3);
            var repParams1 = this.currentReport.substr(
              subRep3,
              indNext3 - subRep3
            );

            if (repParams1 && repParams1.indexOf(".cgi") < 0) {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                "qsc" +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-scores-" +
                repParams1 +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                "qsc" +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
            {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                "qsc" +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if  (this.tablePick === "Draw") {
            var subRep5 = this.currentReport.lastIndexOf("round=") + 6;
            var indNext5 = this.currentReport.indexOf("~", subRep5);
            var drawRnd5 = this.currentReport.substr(
              subRep5,
              indNext5 - subRep5
            );
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              "qsc" +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-draw-R" +
              drawRnd5 +
              "-draw.json";
          } else if  (this.tablePick === "Final Result") {
            var subRep4 = this.currentReport.lastIndexOf("result=") + 7;
            var indNext4 = this.currentReport.indexOf("~", subRep4);
            var resNum4 = this.currentReport.substr(
              subRep4,
              indNext4 - subRep4
            );
            // console.log("resNum === ");
            // console.log(resNum1);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              "qsc" +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-result-result-" +
              resNum4 +
              ".json";
          } else {
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              "qsc" +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-result-result-PF.json";
          }
        }
        return url;
      } else {
        if (this.tablePick === "Entries") {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.develop +
            "/" +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-entry-entries.json";
        } else {
          if (this.currentReport.indexOf("tmscore") !== -1) {
            var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
            var indNext2 = this.currentReport.indexOf("~", subRep2);
            var repParams = this.currentReport.substr(
              subRep2,
              indNext2 - subRep2
            );

            if (repParams && repParams.indexOf(".cgi") < 0) {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
               this.develop +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-scores-" +
                repParams +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
            {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.course_code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.tablePick === "Draw") {
            var subRep = this.currentReport.lastIndexOf("round=") + 6;
            var indNext = this.currentReport.indexOf("~", subRep);
            var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-draw-R" +
              drawRnd +
              "-draw.json";
          } else if (this.tablePick === "Final Result") {
            var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
            var indNext1 = this.currentReport.indexOf("~", subRep1);
            var resNum1 = this.currentReport.substr(
              subRep1,
              indNext1 - subRep1
            );
            // console.log("resNum === ");
            // console.log(resNum1);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-result-result-" +
              resNum1 +
              ".json";
          } else {
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-result-result-PF.json";
          }
        }
        return url;
      }
    },
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Live") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
::v-deep span.AltText {
  display: none!important;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
::v-deep .btn-secondary:focus, .btn-secondary.focus {
  color: #7E7E7E;
  background-color: rgb(255, 255, 255);
  border-color: #ffffff;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none!important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  /* border-left: 0.3em solid transparent; */
  float: right;
  margin-top: 10px;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center / 12px 8px no-repeat;
}
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #7E7E7E !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 16px !important;
}
::v-deep .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529 !important;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  width: 367px;
}
::v-deep .btn-secondary {
  color: #7E7E7E;
  background-color: #ffffff;
  border-color: #ebebeb;
  border-radius: 0px;
  font-size: 16px;
  width: 365px;
  height: 43px;
  border: 0px solid;
  text-align: left;
  margin-bottom: 30px;
  padding-left: 25px!important;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center / 12px 8px no-repeat;
}
p.LiveScoreMessPara {
  color: #fff;
  padding-top: 15px;
}
.LiveScoreMess {
  background: #133f7b;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
.colorMess {
  background: red !important;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.title {
  text-align: center;
  background-color: #133f7b;
  color: white;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 14px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #042F62;
  padding: 9px;
  font-size: 14px;
}
#reportTable {
  padding-bottom: 50px;
}
.developGreen > tr > th {
  background-color: #76c3bd !important;
}
.win {
  background-color: #02b2dd !important;
  color: black;
}
.catD {
  background-color: #A8B7C9;
  color: #fff;
}
.drawName {
  font-size: 14px !important;
}
.drawText {
  font-size: 14px !important;
  padding: 0 !important;
}

table {
  width: 100%;
}
tr > th:first-child {
  border-top-left-radius: 10px;
}
tr > th:last-child {
  border-top-right-radius: 10px;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: #fff !important;
}
.even {
  background-color: #f9f9f9 !important;
}
tr:nth-child(odd) {
  background-color: #F5F5F5;
}
tr:nth-child(even) {
  background-color: #F5F5F5!important;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: black;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
}
td {
  padding-top: 15px !important;
  padding-right: 6px !important;
  padding-bottom: 16px !important;
  font-size: 19px !important;
  padding-left: 8px !important;
  font-weight: 400;
  color: #7E7E7E;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  border: 0.25px solid #e2e1e1
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 12px !important;
  padding-right: 6px !important;
  padding-bottom: 12px !important;
  font-size: 19px;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
@media only screen and (max-width: 768px) {
  td {
    font-size: 12px !important;
  }
  th {
    font-size: 13px;
    padding: 6px;
  }
  .mobHide {
    display: none;
  }
  #reportCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

</style>

<style scoped src="@/css/entries.css"></style>
<style scoped src="@/css/ADTentries.css"></style>
<style scoped src="@/css/draw.css"></style>
<style scoped src="@/css/ADTdraw.css"></style>
<style scoped src="@/css/results.css"></style>
<style scoped src="@/css/ADTresults.css"></style>
<style src="@/css/scoresOnly.css"></style>
<style src="@/css/ADTscoresOnly.css"></style>
<style src="@/css/teamscoresOnly.css"></style>
<style src="@/css/hbh.css"></style>
<style src="@/css/ADThbh.css"></style>
<style src="@/css/orginalReportCard.css"></style>
<style src="@/css/ADTorginalReportCard.css"></style>
<style src="@/css/playerReportCard.css"></style>
<style src="@/css/ADTplayerReportCard.css"></style>